import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import ReturnToCategory from "../Subnav/ReturnToCategory";
import ReturnToShow from "../Subnav/ReturnToShow";
import TitleDetailContent from "./Content/TitleDetailContent";
import TitleMediaSingle from "./TitleMedia/TitleMediaSingle";
import CompetitionsAwards from "./CompetitionsAwards/CompetitionsAwards";
import CompetitionsAwardsColumns from "./CompetitionsAwards/CompetitionsAwardsColumns";
import Sidebar from "./Sidebar/Sidebar";
import { Helmet } from "react-helmet";
import {
	getShowDetailPageData,
	getShowAwards,
} from "../../utils/api/helpers/ShowDetail";
import { getShowMediaByTag } from "../../utils/api/helpers/Media";
import { listVideosByShowSlug } from "../../utils/api/helpers/video";
import { getEventsByShowSlug } from "../../utils/api/helpers/event";

import {
	detailProject,
	fetchProjectScreeners,
} from "../../utils/api/helpers/indee";
import TitleDetailNav from "./Nav/TitleDetailNav";

import titleDetailBannerPlaceholder from "../../Common/_assets/img/placeholder/titledetail-banner-placeholder.png";
import ReactGA from "react-ga4";

const TitleDetail = ({
	isLoggedIn,
	displayPinModal,
	setDisplayPinModal,
	setDisplayScreenerProjectList,
	activeProjectKey,
	setActiveProjectKey,
	setActiveTitleName,
	isMobile,
	indeeApiChecked,
}) => {
	const { categorySlug, titleSlug, mediaSection } = useParams();

	const bannerRef = useRef();

	const [titleId, setTitleId] = useState(""),
		[titleName, setTitleName] = useState(""),
		[titleBGImage, setTitleBGImage] = useState(null),
		[titleTTImage, setTitleTTImage] = useState(null),
		[scrolled, setScrolled] = useState(false),
		[socialIG, setSocialIG] = useState(),
		[socialTW, setSocialTW] = useState(),
		[socialFB, setSocialFB] = useState(),
		[reviewQuote, setReviewQuote] = useState(null),
		[reviewCredit, setReviewCredit] = useState(null),
		[viewingLink, setViewingLink] = useState(null),
		[indeeProjectKey, setIndeeProjectKey] = useState(null),
		[sagIneligible, setSagIneligible] = useState(false),
		[trailerLoaded, setTrailerLoaded] = useState(false),
		[trailerId, setTrailerId] = useState(null),
		[availableOn, setAvailableOn] = useState(null),
		[competitions, setCompetitions] = useState(null),
		[hasAwards, setHasAwards] = useState(false),
		[hasVideos, setHasVideos] = useState(false),
		[hasEvents, setHasEvents] = useState(false),
		[hasPress, setHasPress] = useState(false),
		// [hasImages, setHasImages] = useState(false),
		[hasPresentations, setHasPresentations] = useState(false),
		[hasContent, setHasContent] = useState(false),
		[taglist_videos, set_taglist_videos] = useState(null),
		[fyseeVideoList, setFyseeVideoList] = useState(null),
		[hasMediaRead, setHasMediaRead] = useState(false),
		[scripts, setScripts] = useState(null),
		[taglist_presentations, set_taglist_presentations] = useState(null),
		[taglist_press, set_taglist_press] = useState(null),
		[taglist_press_videos, set_taglist_press_videos] = useState(null),
		[award_count, set_award_count] = useState(null),
		[eventList, setEventList] = useState(null),
		[activeSection, setActiveSection] = useState(null),
		[inSubRoute, setInSubRoute] = useState(false),
		[bannerHeight, setBannerHeight] = useState(null);

	/** INDEE STATE **/
	const [indeeProjectLoaded, setIndeeProjectLoaded] = useState(false);

	const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/";

	useEffect(() => {
		getShowDetailPageData(categorySlug, titleSlug)
			.then((result) => {
				if (
					result.data &&
					result.data.show_id &&
					result.status === 200
				) {
					// console.log('Title Detail',result.data)
					setScrolled(false);
					setTitleName(result.data.title);
					setActiveTitleName(result.data.title);
					setSocialTW(
						result.data.twitter ? result.data.twitter : null,
					);
					setSocialFB(
						result.data.facebook ? result.data.facebook : null,
					);
					setSocialIG(
						result.data.instagram ? result.data.instagram : null,
					);
					setViewingLink(result.data.viewing_link);
					setIndeeProjectKey(result.data.indee_project_key);

					setReviewQuote(result.data.review_quote);
					setReviewQuote(result.data.review_quote);
					setReviewCredit(result.data.review_credit);

					let avail_on = result.data.available_on
						? result.data.available_on.split("T")[0]
						: null;
					setAvailableOn(avail_on);

					setSagIneligible(result.data.sag_ineligible);

					if (result.data.trailer_id) {
						setTrailerId(result.data.trailer_id);
						setTrailerLoaded(true);
					}

					setTitleTTImage(s3Url + result.data.titleTreatment);
					setTitleBGImage(s3Url + result.data.backgroundImage);
					setTitleId(0);
				} else {
					window.location.href = "/";
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug, mediaSection, setActiveTitleName]);

	useEffect(() => {
		getShowAwards(categorySlug, titleSlug)
			.then((result) => {
				// console.log("Show Awards", result)
				if (
					result &&
					result.data &&
					Object.keys(result.data).length > 0
				) {
					// console.log('awards', result.data)

					if (
						result.data.some((r) => r.group.length > 0) ||
						result.data.some((r) => r.individual.length > 0) ||
						result.data.some((r) => r.program.length > 0)
					) {
						setCompetitions(
							result.data.filter((r) => {
								return (
									r.group.length > 0 ||
									r.individual.length > 0 ||
									r.program.length > 0
								);
							}),
						);
						// setHasAwards(true);
						// setHasContent(true);
						setHasAwards(false);
						set_award_count(null);
						// set_award_count(
						// 	result.data[0].group.length +
						// 		result.data[0].program.length +
						// 		result.data[0].individual.length,
						// );
						// console.log(result.data[0].group.length, result.data[0].program.length, result.data[0].individual.length)
					}
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug]);

	useEffect(() => {
		getShowMediaByTag(categorySlug, titleSlug, "script")
			.then((result) => {
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data).length > 0
				) {
					// console.log("Media by tag", result.data)
					setScripts(result.data.reverse());
					setHasMediaRead(true);
					setHasContent(true);
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug]);

	useEffect(() => {
		getShowMediaByTag(categorySlug, titleSlug, "video")
			.then((result) => {
				// console.log("Media by tag", result)
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data).length > 0
				) {
					set_taglist_videos(result.data);
					setHasVideos(true);
					setHasContent(true);
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug]);

	useEffect(() => {
		getShowMediaByTag(categorySlug, titleSlug, "press")
			.then((result) => {
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data).length > 0
				) {
					// console.log("Media by tag press", result)
					set_taglist_press(result.data);
					setHasContent(true);
					setHasPress(true);
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug]);

	useEffect(() => {
		getShowMediaByTag(categorySlug, titleSlug, "press-video")
			.then((result) => {
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data).length > 0
				) {
					// console.log("set_taglist_press_videos", result.data)
					set_taglist_press_videos(result.data);
					setHasContent(true);
					setHasPress(true);
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug]);

	useEffect(() => {
		getShowMediaByTag(categorySlug, titleSlug, "design-presentations")
			.then((result) => {
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data).length > 0
				) {
					console.log("set_taglist_presentations", result.data);
					set_taglist_presentations(result.data);
					setHasContent(true);
					setHasPresentations(true);
				}
			})
			.catch(() => {});
	}, [categorySlug, titleSlug]);

	useEffect(() => {
		listVideosByShowSlug(titleSlug)
			.then((result) => {
				// console.log("Video", result)
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data).length > 0
				) {
					setFyseeVideoList(result.data);
					setHasVideos(true);
					setHasContent(true);
				}
			})
			.catch(() => {});
	}, [titleSlug]);

	useEffect(() => {
		getEventsByShowSlug(titleSlug)
			.then((result) => {
				// console.log("Events", result)
				if (
					result &&
					result.data &&
					result.status === 200 &&
					Object.keys(result.data[0]).length > 0
				) {
					setEventList(result.data[0]);
					setHasEvents(true);
					setHasContent(true);
				}
			})
			.catch(() => {});
	}, [titleSlug]);

	/**INDEE**/
	useEffect(() => {
		if (!indeeProjectLoaded && indeeProjectKey)
			detailProject(indeeProjectKey).then((result) => {
				// console.log("INDEE detailProject", result)
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indeeProjectKey, indeeProjectLoaded]);

	useEffect(() => {
		if (!indeeProjectLoaded && indeeProjectKey)
			fetchProjectScreeners(indeeProjectKey).then((result) => {
				setIndeeProjectLoaded(true);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indeeProjectKey, indeeProjectLoaded]);
	/**END INDEE**/

	useEffect(() => {
		ReactGA.event({
			category: "Title Page View",
			action: titleName + " | Title Page View",
			label: titleName + " | Title Page View",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [titleSlug]);

	useEffect(() => {
		setTimeout(() => {
			if (titleBGImage && bannerRef) {
				// console.log(bannerRef.current.clientHeight)
				setBannerHeight(bannerRef.current.clientHeight);
			}
		}, 50);

		const handleBannerResize = () => {
			setBannerHeight(bannerRef.current.clientHeight);
		};
		window.addEventListener("resize", handleBannerResize);
		return () => {
			window.removeEventListener("resize", handleBannerResize);
		};
	}, [titleBGImage, bannerRef]);

	return (
		<>
			<Helmet>
				<title>
					Netflix FYC | Series - {titleName ? titleName : ""}
				</title>
			</Helmet>

			<div className="subnav__container--absolute d-flex align-items-center">
				{inSubRoute ? (
					<ReturnToShow
						categorySlug={categorySlug}
						titleSlug={titleSlug}
						titleName={titleName}
					/>
				) : (
					<ReturnToCategory />
				)}
			</div>

			<div className="titledetail-content__container content__container d-flex flex-wrap flex-md-fill">
				<div
					className={"d-block d-md-none"}
					style={{
						backgroundColor: "#151616",
						height: "75px",
						width: "100%",
						position: "fixed",
						top: 0,
						left: 0,
						zIndex: 2,
						pointerEvents: "none",
					}}
				/>

				{titleBGImage !== "" ? (
					<div
						className={
							"titledetail-banner " +
							(scrolled || trailerLoaded || mediaSection
								? ""
								: "")
						}
						style={{
							backgroundImage: "url(" + titleBGImage + ")",
							position:
								!inSubRoute && !isMobile
									? "sticky"
									: inSubRoute && !isMobile
										? "fixed"
										: "relative",
							top: isMobile
								? 0
								: !bannerHeight
									? 0
									: bannerHeight * -1 + 120,
							marginTop: isMobile ? 75 : 0,
							// top: bannerHeight*-1+100,
							zIndex: 3,
							pointerEvents: "none",
						}}
					>
						<div
							style={{
								position: "relative",
								width: "100%",
								height: "100%",
							}}
						>
							<div
								style={{
									position: "relative",
									width: "100%",
								}}
							>
								<div className="titledetail__banner--gradient titledetail__banner--gradient-top" />
								<img
									src={titleDetailBannerPlaceholder}
									alt={titleName}
									ref={bannerRef}
									className={"w-100"}
									style={{
										zIndex: 0,
										pointerEvents: "none",
									}}
								/>
								<div className="titledetail__banner--gradient" />
							</div>

							<div
								className={
									"width_ctrl titledetail-banner-nav-container"
								}
							>
								{titleTTImage !== "" ? (
									<div
										className={
											"px-3 w-100 d-flex justify-content-center"
										}
									>
										<img
											src={titleTTImage}
											style={{ maxHeight: "250px" }}
											className="d-block d-md-none titletreatment"
											alt={titleName}
										/>
									</div>
								) : (
									""
								)}

								{hasContent && !inSubRoute ? (
									<div
										className={
											"col-12 col-md-8 ms-md-auto ps-md-5 pe-md-4 pt-2 pt-md-0"
										}
									>
										<TitleDetailNav
											activeSection={activeSection}
											setActiveSection={setActiveSection}
											hasMediaRead={hasMediaRead}
											hasVideos={hasVideos}
											hasEvents={hasEvents}
											hasPress={hasPress}
											isLoggedIn={isLoggedIn}
											hasAwards={hasAwards}
											isMobile={isMobile}
											hasPresentations={hasPresentations}
										/>
									</div>
								) : null}
							</div>

							<div className="titledetail__banner--gradient titledetail__banner--gradient-flipped" />
						</div>
					</div>
				) : null}

				<div className="width_ctrl d-flex flex-column flex-md-row position-relative">
					<Sidebar
						award_count={award_count}
						titleName={titleName}
						titleId={titleId}
						titleSlug={titleSlug}
						titleTTImage={titleTTImage}
						socialFB={socialFB}
						socialTW={socialTW}
						socialIG={socialIG}
						sagIneligible={sagIneligible}
						reviewQuote={reviewQuote}
						reviewCredit={reviewCredit}
						viewingLink={viewingLink}
						availableOn={availableOn}
						hasAwards={hasAwards}
						competitions={competitions}
						indeeProjectKey={indeeProjectKey}
						isLoggedIn={isLoggedIn}
						displayPinModal={displayPinModal}
						setDisplayPinModal={setDisplayPinModal}
						setDisplayScreenerProjectList={
							setDisplayScreenerProjectList
						}
						activeProjectKey={activeProjectKey}
						setActiveProjectKey={setActiveProjectKey}
						setActiveTitleName={setActiveTitleName}
						hasContent={hasContent}
						isMobile={isMobile}
						bannerHeight={bannerHeight}
						indeeApiChecked={indeeApiChecked}
					/>

					<Routes>
						<Route
							path={"/"}
							element={
								<TitleDetailContent
									s3Url={s3Url}
									titleId={titleId}
									trailerId={trailerId}
									titleSlug={titleSlug}
									categorySlug={categorySlug}
									titleName={titleName}
									award_count={award_count}
									competitions={competitions}
									scripts={scripts}
									hasMediaRead={hasMediaRead}
									hasVideos={hasVideos}
									hasEvents={hasEvents}
									hasPress={hasPress}
									hasAwards={hasAwards}
									hasContent={hasContent}
									fyseeVideoList={fyseeVideoList}
									taglist_videos={taglist_videos}
									taglist_press={taglist_press}
									taglist_press_videos={taglist_press_videos}
									eventList={eventList}
									indeeProjectKey={indeeProjectKey}
									indeeProjectLoaded={indeeProjectLoaded}
									scrolled={scrolled}
									setScrolled={setScrolled}
									activeSection={activeSection}
									setActiveSection={setActiveSection}
									isMobile={isMobile}
									socialFB={socialFB}
									socialTW={socialTW}
									socialIG={socialIG}
									reviewQuote={reviewQuote}
									reviewCredit={reviewCredit}
									setActiveProjectKey={setActiveProjectKey}
									isLoggedIn={isLoggedIn}
									setDisplayPinModal={setDisplayPinModal}
									setDisplayScreenerProjectList={
										setDisplayScreenerProjectList
									}
									setActiveTitleName={setActiveTitleName}
									sagIneligible={sagIneligible}
									availableOn={availableOn}
									hasPresentations={hasPresentations}
									taglist_presentations={
										taglist_presentations
									}
									indeeApiChecked={indeeApiChecked}
								/>
							}
						/>

						<Route
							path={"/:mediaSection"}
							element={
								<TitleDetailContent
									s3Url={s3Url}
									titleId={titleId}
									trailerId={trailerId}
									titleSlug={titleSlug}
									categorySlug={categorySlug}
									titleName={titleName}
									award_count={award_count}
									competitions={competitions}
									scripts={scripts}
									hasMediaRead={hasMediaRead}
									hasVideos={hasVideos}
									hasEvents={hasEvents}
									hasPress={hasPress}
									hasAwards={hasAwards}
									hasContent={hasContent}
									fyseeVideoList={fyseeVideoList}
									taglist_videos={taglist_videos}
									taglist_press={taglist_press}
									taglist_press_videos={taglist_press_videos}
									eventList={eventList}
									indeeProjectKey={indeeProjectKey}
									indeeProjectLoaded={indeeProjectLoaded}
									scrolled={scrolled}
									setScrolled={setScrolled}
									activeSection={activeSection}
									setActiveSection={setActiveSection}
									isMobile={isMobile}
									socialFB={socialFB}
									socialTW={socialTW}
									socialIG={socialIG}
									reviewQuote={reviewQuote}
									reviewCredit={reviewCredit}
									setActiveProjectKey={setActiveProjectKey}
									isLoggedIn={isLoggedIn}
									setDisplayPinModal={setDisplayPinModal}
									setDisplayScreenerProjectList={
										setDisplayScreenerProjectList
									}
									setActiveTitleName={setActiveTitleName}
									sagIneligible={sagIneligible}
									availableOn={availableOn}
									hasPresentations={hasPresentations}
									taglist_presentations={
										taglist_presentations
									}
									indeeApiChecked={indeeApiChecked}
								/>
							}
						/>

						<Route
							exact
							path={"/awards"}
							element={
								<>
									{hasAwards && competitions ? (
										<div className="main__content pt-5 pt-md-0 col-12 col-md-8 ps-0 ps-md-5 pe-0 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
											<div
												className="scroll-container"
												style={{
													maxHeight: "75vh",
													overflowY: "scroll",
													width: "100%",
												}}
											>
												<CompetitionsAwardsColumns
													show_id={titleId}
													slug={titleSlug}
													titleName={titleName}
													award_count={award_count}
													competitions={competitions}
												/>
											</div>
										</div>
									) : null}
								</>
							}
						/>
						<Route
							exact
							path={"/pga/awards"}
							element={
								<>
									{hasAwards && competitions ? (
										<div className="main__content pt-5 pt-md-0 col-12 col-md-8 ps-0 ps-md-5 pe-0 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
											<div
												className="scroll-container"
												style={{
													maxHeight: "75vh",
													overflowY: "scroll",
													width: "100%",
												}}
											>
												<CompetitionsAwardsColumns
													show_id={titleId}
													slug={titleSlug}
													titleName={titleName}
													award_count={award_count}
													competitions={competitions}
												/>
											</div>
										</div>
									) : null}
								</>
							}
						/>

						<Route
							exact
							path={"/ves/awards"}
							element={
								<>
									{hasAwards && competitions ? (
										<div className="main__content pt-5 pt-md-0 col-12 col-md-8 ps-0 ps-md-5 pe-0 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
											<div
												className="scroll-container"
												style={{
													maxHeight: "75vh",
													overflowY: "scroll",
													width: "100%",
												}}
											>
												<CompetitionsAwardsColumns
													show_id={titleId}
													slug={titleSlug}
													titleName={titleName}
													award_count={award_count}
													competitions={competitions}
												/>
											</div>
										</div>
									) : null}
								</>
							}
						/>

						<Route
							path={"/:mediaSection/:mediaSlug"}
							element={
								<>
									<div className="main__content pt-0 pt-md-0 col-12 col-md-8 px-3 px-md-0 ps-md-5 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
										<div className="d-block w-100 flex-fill">
											{
												<TitleMediaSingle
													setInSubRoute={
														setInSubRoute
													}
												/>
											}
										</div>
									</div>
									{hasAwards && competitions ? (
										<div className="d-flex d-md-none my-5 pb-5 flex-md-fill flex-column">
											<CompetitionsAwards
												show_id={titleId}
												slug={titleSlug}
												titleName={titleName}
												award_count={award_count}
												competitions={competitions}
											/>
										</div>
									) : (
										""
									)}
								</>
							}
						/>
						<Route
							path={"/pga/:mediaSection/:mediaSlug"}
							element={
								<>
									<div className="main__content pt-0 pt-md-0 col-12 col-md-8 px-3 px-md-0 ps-md-5 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
										<div className="d-block w-100 flex-fill">
											{
												<TitleMediaSingle
													setInSubRoute={
														setInSubRoute
													}
												/>
											}
										</div>
									</div>

									{hasAwards && competitions ? (
										<div className="d-flex d-md-none my-5 pb-5 flex-md-fill flex-column">
											<CompetitionsAwards
												show_id={titleId}
												slug={titleSlug}
												titleName={titleName}
												award_count={award_count}
												competitions={competitions}
											/>
										</div>
									) : (
										""
									)}
								</>
							}
						/>
						<Route
							path={"/ves/:mediaSection/:mediaSlug"}
							element={
								<>
									<div className="main__content pt-0 pt-md-0 col-12 col-md-8 px-3 px-md-0 ps-md-5 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
										<div className="d-block w-100 flex-fill">
											{
												<TitleMediaSingle
													setInSubRoute={
														setInSubRoute
													}
												/>
											}
										</div>
									</div>
									{hasAwards && competitions ? (
										<div className="d-flex d-md-none my-5 pb-5 flex-md-fill flex-column">
											<CompetitionsAwards
												show_id={titleId}
												slug={titleSlug}
												titleName={titleName}
												award_count={award_count}
												competitions={competitions}
											/>
										</div>
									) : (
										""
									)}
								</>
							}
						/>
					</Routes>
					{titleBGImage ? (
						<div className="titledetail-bg-streams" />
					) : null}
				</div>
			</div>
		</>
	);
};
export default TitleDetail;
